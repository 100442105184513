import {
    ButtonBase,
    Card,
    CircularProgress,
    IconButton,
    ListItem,
    TableCell,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import styled, { css } from 'styled-components';

export const BiddingProcessPageWrapper = styled(PageWrapper)`
    > div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`;

export const ProcessDescriptionCard = styled(Card)`
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    > header {
        display: flex;
        align-items: center;
        position: relative;
        background-color: var(--background-color);
        gap: 0.5rem;

        > img {
            height: 1.75rem;
            border: 1px solid var(--border-color);
            padding: 4px;
            border-radius: 4px;
            aspect-ratio: 1/1;
            background-color: var(--background-color);
        }

        > h1 {
            font-size: 1.5rem;
            font-weight: 400;
            color: var(--text-color);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0;
            padding: 0;
        }

        > div {
            margin-left: auto;
        }
    }

    > section {
        margin-top: 1rem;
        flex: 1;
        overflow-y: auto;
    }
`;

export const ProcessDescriptionArrowButton = styled(IconButton)<{ expanded: boolean }>`
    transform: ${({ expanded }) => (expanded ? 'rotateZ(180deg)' : 'rotateZ(0)')};
    transition: transform 0.4s;
`;

export const ProcessDescriptionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    > div {
        flex: 1;
        padding: 0 1rem;

        > p {
            display: flex;
            gap: 0.5rem;
            align-items: center;

            > strong {
                display: flex;
                align-items: center;
            }
        }
    }
`;

export const LotTableHeaderCell = styled(TableCell)`
    padding: 1rem !important;
`;

export const LotTableBodyCell = styled(TableCell)`
    padding: 0.5rem 1rem !important;
`;

export const ProcessNoticesContainer = styled.div<{ loading?: boolean; empty?: boolean }>`
    flex-direction: column;
    padding-top: 1rem;
    display: flex;
    gap: 0.5rem;

    ${({ loading, empty }) =>
        (loading || empty) &&
        css`
            height: 10rem;
            align-items: center;
            justify-content: center;
            > h3 {
                color: var(--text-light-color);
                font-weight: 400;
            }
        `}
`;

export const ProcessDocumentsContainer = styled.div`
    flex-wrap: wrap;
    display: flex;
    gap: 1rem;

    > div {
        max-height: 60vh;
        min-width: 300px;
        flex: 1;

        :first-child {
            flex: 2;
            min-width: 300px;
        }

        > section {
            ::-webkit-scrollbar {
                width: 6px;
            }

            ::-webkit-scrollbar-track {
                background-color: transparent;
            }

            ::-webkit-scrollbar-thumb {
                background-color: #0001;
                border-radius: 2px;
                transition: 0.4s;
            }
        }
    }
`;

export const ProcessDocumentTitleContainer = styled(ListItem)<{ button?: boolean }>`
    position: sticky;
    background: var(--background-color);
    z-index: 1;
    top: 0;
`;

export const ProcessMessageSection = styled.section`
    flex-direction: column;
    display: flex;
    gap: 0.5rem;
    overflow: hidden !important;

    textarea {
        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #0001;
            border-radius: 2px;
            transition: 0.4s;
        }
    }
`;

export const ProcessMessageListContainer = styled.div<{ loading: boolean; empty: boolean }>`
    overflow-y: auto;
    flex: 1;

    ${({ loading, empty }) =>
        (loading || empty) &&
        css`
            display: flex;
            align-items: center;
            justify-content: center;
            border: ${loading && '2px dotted var(--border-color)'};
            border-radius: 1rem;
        `}

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #0001;
        border-radius: 2px;
        transition: 0.4s;
    }
`;

export const ProcessButton = styled(ButtonBase)<{
    color?: string;
    loading?: boolean;
    outlined?: boolean;
}>`
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    background-color: ${({ outlined, color = 'var(--tertiary-color)' }) =>
        outlined ? 'transparent' : color};
    border-style: solid;
    border-width: 1px;
    border-color: ${({ outlined, color = 'var(--tertiary-color)' }) =>
        outlined ? color : 'transparent'};
    color: ${({ outlined, color = 'var(--tertiary-color)' }) => (outlined ? color : '#fff')};
    transition: color 0.4s, background-color 0.4s;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
    padding: 14px 16px;
    display: flex;
    gap: 8px;

    ::before {
        content: ${({ loading }) => loading && ''};
        position: absolute;
        left: 0;
        width: 0;
        width: 100%;
        height: 100%;
        background-color: ${({ color = 'var(--tertiary-clor)' }) => color};
    }
`;

export const ProcessButtonActivityIndicator = styled(CircularProgress)`
    position: absolute;
`;

export const ExceptionsContainer = styled.div`
    padding-top: 10px;
`;

export const ProcessButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;

    > div {
        display: flex;
        gap: 0.5rem;
    }
`;

export const CleanContainer = styled.div<{ height?: string }>`
    display: flex;
    height: ${({ height }) => height || '10rem'};
    align-items: center;
    justify-content: center;
    > h3 {
        color: var(--text-light-color);
        font-weight: 400;
    }
`;

export const LotsLoadingContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const useStyles = makeStyles(() => ({
    divCircularProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
    },
}));
