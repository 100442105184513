import { CircularProgress } from '@material-ui/core';
import { authRequests } from 'clients/manager/auth.requests';
import { TypeAccess } from 'common/interfaces/user.interface';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { removeAuthToken, setAuthToken, setAuthUserToken } from 'modules/auth/session.utils';
import { apiRedirectTo, getBackofficeRedirectUrl, getUrlOAuth2 } from 'common/utils';
import { t } from 'i18next';
import { SignUpProviderContext } from 'common/components/base/pages/context/SignUpProviderContext';
import { usePlatformContext } from 'routes/platform.context';
import useStyles from './styles';

export const OAuth2Callback = () => {
    const clientId = process.env.REACT_APP_OAUTH2_CLIENT_ID || '';
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code') || '';
    const state = params.get('state') || '';
    const redirectUriStr = params.get('redirectUri') || '';
    let redirectUri = redirectUriStr;
    const loginBaseUrl = getBackofficeRedirectUrl(true);
    const phpBaseUrl = getBackofficeRedirectUrl();

    if (redirectUriStr) {
        redirectUri = redirectUriStr.startsWith('/') ? redirectUriStr : `/${redirectUriStr}`;
    }

    const [token, setToken] = useState<string | undefined>();
    const [isInvalidToken, setIsInvalidToken] = useState<boolean>(false);
    const [typeAccess, setTypeAccess] = useState<TypeAccess>(TypeAccess.citizen);
    const [companyId, setCompanyId] = useState<string | undefined>();
    const [error, setError] = useState<{ token: string; message: string } | undefined>();
    const { handleNext } = useContext(SignUpProviderContext);
    const { setIsOauth2 } = usePlatformContext();

    const styles = useStyles();
    const history = useHistory();
    if (redirectUri.includes('logout')) {
        removeAuthToken(true);
        apiRedirectTo({
            backofficeRedirectUrl: phpBaseUrl,
            redirectUrl: 'logout',
            isPublic: true,
        });
        return (
            <div className={styles.redirectMain}>
                <div className={styles.loading}>
                    <CircularProgress className={clsx(styles.loading)} />
                </div>
            </div>
        );
    }

    if (redirectUri === '/cadastro-fornecedor') {
        handleNext(2);
    }

    if (redirectUri === '/cadastro') {
        setIsOauth2(true);
        history.push('/cadastro');
    }

    useEffect(() => {
        const getTokenByCode = async () => {
            if (code && state) {
                const response = await authRequests
                    .getTokenByCode({
                        clientId,
                        code,
                        state,
                    })
                    .catch((e) => {
                        setIsInvalidToken(true);
                        if (e.response?.data) {
                            const errMsg = e?.response?.data;
                            const token = 'UNEXPECTED_ERROR';
                            const { message } = errMsg;
                            setError({
                                token,
                                message: message
                                    ? t(`oauth2.${message}`)
                                    : t('dispute-button.warning-title'),
                            });
                        } else {
                            setError({
                                token: 'UNEXPECTED_ERROR',
                                message: t('dispute-button.warning-title'),
                            });
                        }
                        return null;
                    });

                if (response?.data) {
                    const { data } = response;
                    setIsInvalidToken(false);
                    setTypeAccess(data.typeAccess);
                    if (data.companyId) {
                        setCompanyId(`${data.companyId}`);
                    }
                    setToken(data.accessToken);
                }
            }
        };
        getTokenByCode();
    }, []);

    useEffect(() => {
        const auth = async () => {
            if (token) {
                setAuthUserToken(token);
                setAuthToken(token);
                switch (typeAccess) {
                    case TypeAccess.management:
                    case TypeAccess.organization:
                        if (redirectUri) {
                            history.push(redirectUri);
                        } else {
                            const redirectUrl = getUrlOAuth2(
                                typeAccess,
                                companyId,
                                '/painel',
                                phpBaseUrl
                            );
                            apiRedirectTo({
                                backofficeRedirectUrl: loginBaseUrl,
                                redirectUrl,
                                isPublic: true,
                            });
                        }
                        break;
                    case TypeAccess.provider:
                        history.push(redirectUri || '/painel-fornecedor/');
                        break;
                    default:
                        history.push(redirectUri || '/painel-cidadao/');
                        break;
                }
            }
        };

        auth();
    }, [token]);

    const url = apiRedirectTo({
        backofficeRedirectUrl: loginBaseUrl,
        redirectUrl: 'painel',
        isDisableRedirect: true,
    });

    return (
        <div className={styles.main}>
            {isInvalidToken ? (
                <div className={styles.loading}>
                    <div className={styles.container}>
                        <h1 className={styles.errorTitle}>{t('term.error')}</h1>
                        <strong className={styles.errorDescription}> {error?.message} </strong>
                        <p className={styles.errorDescription}> {t('oauth2.back')} </p>
                        <a href={url} className='back-link'>
                            {t('password-reset.back-to-dashboard')}
                        </a>
                    </div>
                </div>
            ) : (
                <div className={styles.loading}>
                    <CircularProgress className={clsx(styles.loading)} />
                </div>
            )}
        </div>
    );
};
