import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    MenuItem,
    Typography,
    Checkbox,
    FormControlLabel,
    CircularProgress,
} from '@material-ui/core';
import { Button, TextField } from 'common/components';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validateCpf, validateCnpj } from 'common/utils';
import { SignUpProviderContext } from 'common/components/base/pages/context/SignUpProviderContext';
import IdentificationNumberTextField from 'common/components/identification-number-text-field';
import PersonPhoneInput from 'common/components/phone-input';
import { useIBGECountries } from 'common/hooks/use-ibge-countries.hook';
import { CONSTANTS } from 'common/constants';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import CodeValidator from 'common/components/code-validator';

const Identification = ({ loading, setLoading }) => {
    const { t } = useTranslation();
    const { IBGEcountries } = useIBGECountries();

    const [country, setCountry] = useState(CONSTANTS.ibgeCodes.BRAZIL);
    const [personType, setPersonType] = useState<string>(t('term.legal-person'));
    const [exemptStateRegistration, setExemptStateRegistration] = useState(false);
    const [openCodeValidator, setOpenCodeValidator] = useState(false);
    const [stateCompanyRegistrationNumber, setStateCompanyRegistrationNumber] = useState<
        string | null
    >(null);

    const {
        provider,
        setProvider,
        handleNext,
        docNumberTextError,
        userProviderExist,
        cnpjWsProviderInfo,
        hasFoundedCnpjWsInfo,
    } = useContext(SignUpProviderContext);

    const initialValues = {
        docNumber: provider?.docNumber,
        docType: '',
        companyName: provider?.companyName,
        tradingName: provider?.tradingName,
        emailForNotifications: provider?.emailForNotifications,
        phoneNumber: provider?.phoneNumber,
        emailForBilling: provider?.emailForBilling,
        billingPhoneNumber: provider?.billingPhoneNumber,
        stateCompanyRegistrationNumber: provider?.stateCompanyRegistrationNumber,
        socialSecurityCode: provider?.socialSecurityCode,
        itsSimpleCompany: provider?.itsSimpleCompany,
        birthdate: provider?.birthdate,
        gender: provider?.gender,
        docNumberMei: provider?.docNumberMei,
        country,
        personType,
        verificationCode: '',
    };

    const labelCheckboxExempt = !loading ? t('term.exempt') : '';

    const validationSchema = yup.object({
        docNumber:
            country === CONSTANTS.ibgeCodes.BRAZIL
                ? yup
                      .string()
                      .required(t('cpf-or-cnpj', { ns: 'validation' }))
                      .max(14, t('cpf-or-cnpj-invalid', { ns: 'validation' }))
                      .test(
                          'cpf-cnpj',
                          t('cpf-or-cnpj-invalid', { ns: 'validation' }),
                          (value = '') => {
                              if (value.length <= 11) {
                                  return validateCpf(value);
                              }

                              return validateCnpj(value);
                          }
                      )
                : yup.string().required(t('cpf-or-cnpj', { ns: 'validation' })),
        companyName: yup.string().required(t('corporate-name', { ns: 'validation' })),
        tradingName: yup.string().required(t('fantasy-name', { ns: 'validation' })),
        emailForNotifications: yup
            .string()
            .email(t('please-enter-a-valid-email', { ns: 'validation' }))
            .required(t('please-fill-in-the-data', { ns: 'validation' })),
        phoneNumber: yup
            .string()
            .required(t('please-enter-a-phone-number', { ns: 'validation' }))
            .phone({
                message: t('please-enter-a-valid-phone', { ns: 'validation' }),
            }),
        emailForBilling: yup
            .string()
            .email(t('please-enter-a-valid-email', { ns: 'validation' }))
            .required(t('please-fill-in-the-data', { ns: 'validation' })),
        billingPhoneNumber: yup
            .string()
            .required(t('please-enter-a-phone-number', { ns: 'validation' }))
            .phone({
                message: t('please-enter-a-valid-phone', { ns: 'validation' }),
            }),
        socialSecurityCode:
            country === CONSTANTS.ibgeCodes.BRAZIL && personType === t('term.natural-person')
                ? yup
                      .string()
                      .nullable()
                      .required(t('required-field', { ns: 'validation' }))
                : yup.string(),
        /* eslint-disable no-nested-ternary */
        stateCompanyRegistrationNumber:
            country === CONSTANTS.ibgeCodes.BRAZIL && personType === t('term.legal-person')
                ? exemptStateRegistration
                    ? yup.string().nullable()
                    : yup
                          .string()
                          .nullable()
                          .required(t('required-field', { ns: 'validation' }))
                : yup.string(),
        /* eslint-enable no-nested-ternary */
        country: yup.string().required(t('please-fill-in-the-data', { ns: 'validation' })),
        personType: yup.string().required(t('please-fill-in-the-data', { ns: 'validation' })),
    });

    const onSubmit = (values: any) => {
        if (values.emailForNotifications !== provider?.emailForNotifications) {
            if (!values.verificationCode) {
                setOpenCodeValidator(true);
                return;
            }
        }

        const parsedPhoneNumber = parsePhoneNumber(values.phoneNumber);
        const parsedBillingPhoneNumber = parsePhoneNumber(values.billingPhoneNumber);

        setProvider((prevProvider: any) => ({
            ...prevProvider,
            ...values,
            socialSecurityCode: values.socialSecurityCode || null,
            stateCompanyRegistrationNumber: values.stateCompanyRegistrationNumber || null,
            phoneNumber: parsedPhoneNumber && parsedPhoneNumber.nationalNumber,
            billingPhoneNumber: parsedBillingPhoneNumber && parsedBillingPhoneNumber.nationalNumber,
            phoneCountryCode: parsedPhoneNumber && parsedPhoneNumber.countryCallingCode,
            billingPhoneCountryCode:
                parsedBillingPhoneNumber && parsedBillingPhoneNumber.countryCallingCode,
            docType: personType === t('term.natural-person') ? 'F' : 'J',
        }));

        handleNext();
    };

    const formStep1 = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const checkStateCompanyRegistrationNumber = (
        stateCompanyRegistrationNumber: string | null | undefined
    ) => {
        return stateCompanyRegistrationNumber && stateCompanyRegistrationNumber !== ''
            ? stateCompanyRegistrationNumber
            : 'Isento';
    };

    useEffect(() => {
        if (!loading) {
            setStateCompanyRegistrationNumber(
                checkStateCompanyRegistrationNumber(
                    hasFoundedCnpjWsInfo
                        ? cnpjWsProviderInfo!.stateCompanyRegistrationNumber
                        : formStep1.values.stateCompanyRegistrationNumber
                )
            );
        }
    }, [loading]);

    const onChangeCheckbox = (event: React.ChangeEvent<any>) => {
        const { checked } = event.target;
        setExemptStateRegistration(checked);
        formStep1.setFieldValue('exemptStateRegistration', checked);
        if (checked) {
            setStateCompanyRegistrationNumber('Isento');
            formStep1.setFieldValue('stateCompanyRegistrationNumber', 'Isento');
        } else {
            setStateCompanyRegistrationNumber('');
            formStep1.setFieldValue('stateCompanyRegistrationNumber', null);
        }
    };

    const onChangeStateCompanyRegisterNumber = (event: React.ChangeEvent<any>) => {
        const { value } = event.target;
        if (!loading && !exemptStateRegistration) {
            setStateCompanyRegistrationNumber(value);
            formStep1.handleChange(event);
        }
    };

    const checkboxExempt = !loading ? (
        <Checkbox
            checked={exemptStateRegistration}
            defaultChecked={exemptStateRegistration}
            onChange={onChangeCheckbox}
        />
    ) : (
        <></>
    );

    const formaterDocNumber = (personType, country) => {
        if (country === CONSTANTS.ibgeCodes.BRAZIL && personType === t('term.natural-person')) {
            formStep1.setFieldValue('docNumber', formStep1.values.docNumber?.slice(0, 11));
        }
        if (country === CONSTANTS.ibgeCodes.BRAZIL && personType === t('term.legal-person')) {
            formStep1.setFieldValue('docNumber', formStep1.values.docNumber?.slice(0, 14));
        }
    };

    const countryOnChange = (e) => {
        setCountry(e.target.value);
        formaterDocNumber(formStep1.values.personType, e.target.value);
        formStep1.setFieldValue('country', e.target.value);
    };
    return (
        <Grid container>
            <form onSubmit={formStep1.handleSubmit}>
                <Grid item container spacing={1}>
                    <Grid item xs={3}>
                        <TextField
                            select
                            label={t('term.country')}
                            fullWidth
                            name='country'
                            value={formStep1.values.country}
                            onChange={countryOnChange}
                            error={formStep1.touched.country && !!formStep1.errors.country}
                            helperText={formStep1.touched.country && formStep1.errors.country}
                        >
                            {IBGEcountries.map(({ code, name }) => (
                                <MenuItem key={code} value={code}>
                                    {name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            select
                            label={t('term.type')}
                            fullWidth
                            name='personType'
                            value={formStep1.values.personType}
                            onChange={(e) => {
                                setPersonType(e.target.value);
                                formaterDocNumber(e.target.value, country);
                                formStep1.setFieldValue('personType', e.target.value);
                            }}
                            error={formStep1.touched.personType && !!formStep1.errors.personType}
                            helperText={formStep1.touched.personType && formStep1.errors.personType}
                        >
                            {[t('term.natural-person'), t('term.legal-person')].map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <IdentificationNumberTextField
                            identificationNumber={formStep1.values.docNumber}
                            country={formStep1.values.country}
                            fieldName='docNumber'
                            setExemptStateRegistration={setExemptStateRegistration}
                            setLoading={setLoading}
                            InputProps={{
                                endAdornment: loading && (
                                    <CircularProgress color='secondary' size={20} />
                                ),
                            }}
                            type={
                                formStep1.values.personType === t('term.legal-person')
                                    ? 'cnpj'
                                    : 'cpf'
                            }
                            {...formStep1}
                        />
                    </Grid>
                    {country === CONSTANTS.ibgeCodes.BRAZIL && (
                        <>
                            {formStep1.values.personType === t('term.natural-person') && (
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label={t('term.worker-registration-number')}
                                        name='socialSecurityCode'
                                        value={formStep1.values.socialSecurityCode}
                                        onChange={formStep1.handleChange}
                                        error={
                                            formStep1.touched.socialSecurityCode &&
                                            Boolean(formStep1.errors.socialSecurityCode)
                                        }
                                        helperText={
                                            formStep1.touched.socialSecurityCode &&
                                            formStep1.errors.socialSecurityCode
                                        }
                                    />
                                </Grid>
                            )}
                            {formStep1.values.personType === t('term.legal-person') && (
                                <>
                                    <Grid item xs={2}>
                                        <TextField
                                            disabled={exemptStateRegistration}
                                            fullWidth
                                            label={
                                                formStep1.values.stateCompanyRegistrationNumber ===
                                                'Isento'
                                                    ? ''
                                                    : t('term.state-registration-number')
                                            }
                                            name='stateCompanyRegistrationNumber'
                                            value={stateCompanyRegistrationNumber}
                                            onChange={onChangeStateCompanyRegisterNumber}
                                            error={
                                                formStep1.touched.stateCompanyRegistrationNumber &&
                                                Boolean(
                                                    formStep1.errors.stateCompanyRegistrationNumber
                                                )
                                            }
                                            helperText={
                                                formStep1.touched.stateCompanyRegistrationNumber &&
                                                formStep1.errors.stateCompanyRegistrationNumber
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <FormControlLabel
                                            onChange={onChangeCheckbox}
                                            name='exemptStateRegistration'
                                            control={checkboxExempt}
                                            label={labelCheckboxExempt}
                                        />
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t('term.corporate-name')}
                            disabled={
                                hasFoundedCnpjWsInfo && Boolean(cnpjWsProviderInfo!.companyName)
                            }
                            name='companyName'
                            value={
                                hasFoundedCnpjWsInfo
                                    ? cnpjWsProviderInfo!.companyName
                                    : formStep1.values.companyName ?? ''
                            }
                            onChange={formStep1.handleChange}
                            error={
                                formStep1.touched.companyName &&
                                Boolean(formStep1.errors.companyName)
                            }
                            helperText={
                                formStep1.touched.companyName && formStep1.errors.companyName
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t('term.fantasy-name')}
                            InputLabelProps={{
                                shrink:
                                    hasFoundedCnpjWsInfo || Boolean(formStep1.values.tradingName),
                            }}
                            disabled={
                                hasFoundedCnpjWsInfo && Boolean(cnpjWsProviderInfo!.tradingName)
                            }
                            name='tradingName'
                            value={
                                hasFoundedCnpjWsInfo
                                    ? cnpjWsProviderInfo!.tradingName
                                    : formStep1.values.tradingName ?? ''
                            }
                            onChange={formStep1.handleChange}
                            error={
                                formStep1.touched.tradingName &&
                                Boolean(formStep1.errors.tradingName)
                            }
                            helperText={
                                formStep1.touched.tradingName && formStep1.errors.tradingName
                            }
                        />
                    </Grid>
                </Grid>
                {formStep1.values.personType === t('term.legal-person') && (
                    <Grid item container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                select
                                label={t('term.tax-regime')}
                                disabled={
                                    hasFoundedCnpjWsInfo && Boolean(cnpjWsProviderInfo!.taxRegime)
                                }
                                fullWidth
                                name='itsSimpleCompany'
                                value={formStep1!.values.itsSimpleCompany}
                                SelectProps={{
                                    value: hasFoundedCnpjWsInfo
                                        ? formStep1!.values.itsSimpleCompany
                                        : formStep1.values.itsSimpleCompany ?? '',
                                }}
                                onChange={(e) => {
                                    formStep1.setFieldValue('itsSimpleCompany', e.target.value);
                                }}
                                error={formStep1.touched.docType && !!formStep1.errors.docType}
                                helperText={formStep1.touched.docType && formStep1.errors.docType}
                            >
                                {[
                                    t('term.tax-regime.others'),
                                    t('term.tax-regime.me-epp'),
                                    t('term.tax-regime.mei'),
                                ].map((value, index) => (
                                    <MenuItem key={value} value={index}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {formStep1.values?.itsSimpleCompany === 2 && (
                            <Grid item xs={3}>
                                <IdentificationNumberTextField
                                    identificationNumber={formStep1.values.docNumberMei}
                                    country={formStep1.values.country}
                                    fieldName='docNumberMei'
                                    type='cpf'
                                    {...formStep1}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
                {(formStep1.values?.itsSimpleCompany === 2 ||
                    formStep1.values.personType === t('term.natural-person')) && (
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <KeyboardDatePicker
                                autoOk
                                variant='inline'
                                inputVariant='outlined'
                                label={t('term.birth-date')}
                                name='birthdate'
                                format='DD/MM/yyyy'
                                value={formStep1.values.birthdate}
                                InputAdornmentProps={{ position: 'end' }}
                                invalidDateMessage={t('info.invalid-format', {
                                    ns: 'validation',
                                })}
                                invalidLabel={t('info.invalid-date', { ns: 'validation' })}
                                onChange={(date) => {
                                    formStep1.setFieldValue(
                                        'birthdate',
                                        moment(date).format('YYYY/MM/DD')
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                select
                                label={t('term.gender')}
                                fullWidth
                                name='gender'
                                value={formStep1.values.gender}
                                onChange={(e) => {
                                    formStep1.setFieldValue('gender', e.target.value);
                                }}
                                error={formStep1.touched.docType && !!formStep1.errors.docType}
                                helperText={formStep1.touched.docType && formStep1.errors.docType}
                            >
                                {[t('term.gender.male'), t('term.gender.female')].map((value) => (
                                    <MenuItem key={value} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                id='birthPlace'
                                options={CONSTANTS.municipalityConstants}
                                getOptionLabel={(option) => option.description}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('term.birthplace')} />
                                )}
                                onChange={(e, value) => {
                                    formStep1.setFieldValue('birthplace', value?.id);
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                id='intructionLevel'
                                options={CONSTANTS.instructionLevelConstants}
                                getOptionLabel={(option) => option.description}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('term.instruction-level')} />
                                )}
                                onChange={(e, value) => {
                                    formStep1.setFieldValue('intructionLevel', value?.id);
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                id='profession'
                                options={CONSTANTS.professionConstants}
                                getOptionLabel={(option) => option.description}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('term.profession')} />
                                )}
                                onChange={(e, value) => {
                                    formStep1.setFieldValue('profession', value?.id);
                                }}
                            />
                        </Grid>
                    </Grid>
                )}

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h5' color='primary' style={{ marginBottom: 0 }}>
                            {t('sign-up.pages.stepps.contacts')}
                        </Typography>
                    </Grid>
                    <Grid item md={8} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.email-for-notifications')}
                            name='emailForNotifications'
                            value={formStep1.values.emailForNotifications}
                            onChange={formStep1.handleChange}
                            error={
                                formStep1.touched.emailForNotifications &&
                                Boolean(formStep1.errors.emailForNotifications)
                            }
                            helperText={
                                formStep1.touched.emailForNotifications &&
                                formStep1.errors.emailForNotifications
                            }
                        />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <PhoneInput
                            flags={flags}
                            placeholder={t('term.phone')}
                            value={formStep1.values.phoneNumber}
                            name='phoneNumber'
                            defaultCountry='BR'
                            onChange={(e) => {
                                formStep1.setFieldValue('phoneNumber', e);
                            }}
                            error={
                                formStep1.touched.phoneNumber &&
                                Boolean(formStep1.errors.phoneNumber)
                            }
                            helperText={
                                formStep1.touched.phoneNumber && formStep1.errors.phoneNumber
                            }
                            inputComponent={PersonPhoneInput}
                        />
                    </Grid>
                    <Grid item md={8} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.email-for-billing')}
                            name='emailForBilling'
                            value={formStep1.values.emailForBilling}
                            onChange={formStep1.handleChange}
                            error={
                                formStep1.touched.emailForBilling &&
                                Boolean(formStep1.errors.emailForBilling)
                            }
                            helperText={
                                formStep1.touched.emailForBilling &&
                                formStep1.errors.emailForBilling
                            }
                        />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <PhoneInput
                            flags={flags}
                            placeholder={t('term.billing-phone-number')}
                            value={formStep1.values.billingPhoneNumber}
                            defaultCountry='BR'
                            onChange={(e) => {
                                formStep1.setFieldValue('billingPhoneNumber', e);
                            }}
                            // autoFocus
                            error={
                                formStep1.touched.billingPhoneNumber &&
                                Boolean(formStep1.errors.billingPhoneNumber)
                            }
                            helperText={
                                formStep1.touched.billingPhoneNumber &&
                                formStep1.errors.billingPhoneNumber
                            }
                            inputComponent={PersonPhoneInput}
                        />
                    </Grid>
                </Grid>
                {userProviderExist && (
                    <Typography color='error' style={{ marginTop: 20 }}>
                        {t(
                            'sign-up.pages.stepper-provider.steps.identification.info-error-supplier-exist'
                        )}
                    </Typography>
                )}
                {docNumberTextError && (
                    <Typography align='center' color='error' style={{ marginTop: 20 }}>
                        {docNumberTextError}
                    </Typography>
                )}
                {openCodeValidator && (
                    <CodeValidator
                        email={formStep1.values.emailForNotifications}
                        open={openCodeValidator}
                        onSubmit={async (values) => {
                            await formStep1.setFieldValue(
                                'verificationCode',
                                values.verificationCode,
                                false
                            );
                            setOpenCodeValidator(false);
                            formStep1.handleSubmit();
                        }}
                        onClose={() => setOpenCodeValidator(false)}
                    />
                )}

                <Button size='small' variant='contained' color='primary' type='submit'>
                    {t('term.advance')}
                </Button>
            </form>
        </Grid>
    );
};
export default Identification;
