import { AuctionTypeEnum } from 'common/enums';

interface AuctionNoticeOrganizationResume extends AuctionNoticeOrganization {
    lotsCount: number;
    requestsCount: number;
    providersCount: number;
}

interface AuctionNoticeOrganization {
    id: number;
    auctionType: AuctionType;
    auctionNumber: string;
    accreditationNumber: string;
    startDateTimeDispute: Date;
    simpleDescription: string;
    auctionStartDate: Date;
    auctionEndDate: Date;
    organizationUnitId: number;
    judgmentCriterion: number;
    rateTypeBid: number;
    presidentUserId: number;
    committeeMembers: string;
    biddingStageId: number;
    dateTimeInsert: Date;
    dateTimeUpdate: Date;
    typeCancel: string;
}

enum AuctionType {
    auction = 'E',
    dispense = 'D',
    accreditation = 'C',
    publicSale = 'L',
    concurrence = 'R',
    competition = 'U',
    unenforceability = 'I',
    competitiveDialogue = 'T',
    inPersonConcurrence = 'P',
    inPersonTrading = 'A',
    expressionOfInterest = 'M',
    preQualification = 'Q',
    inPersonAuction = 'N',
    biddingInapplicability = 'B',
}

export enum TypeRegistrationEnum {
    finish = 0,
    normal = 1,
}

export enum ProcessEnum {
    E = 'trading',
    C = 'accreditation',
    R = 'concurrence',
    L = 'auction',
    D = 'dispense',
    I = 'unenforceability',
    T = 'competitiveDialogue',
    U = 'contest',
    P = 'inPersonConcurrence',
    A = 'inPersonTrading',
    M = 'expressionOfInterest',
    Q = 'preQualification',
    N = 'inPersonAuction',
    B = 'biddingInapplicability',
}

enum OriginOfFinancialResourceType {
    national = 'national',
    international = 'international',
}

enum TypeValueBidTypes {
    total = 'total',
    unit = 'unit',
    kit = 'kit',
}

enum ItemGroupingType {
    lot = 'lot',
    item = 'item',
}

enum MethodDispute {
    aberto = 1,
    abertoFechado = 2,
    fechadoAberto = 3,
    fechado = 4,
    dispenseWithSession = 5,
    notApplicable = 6,
}

enum IntegrationTypeFile {
    el = 'el',
    comprasbr = 'comprasbr',
    memory = 'memory',
    excel = 'excel',
    manual = 'manual',
    govbr = 'govbr',
    managementSystem = 'managementSystem',
}

enum JudgmentCriterion {
    notApplicable = 0,
    lowestPrice = 1,
    lowestPriceRate = '1R',
    highestDiscount = 2,
    highestPrice = 3,
    bestTechnique = 4,
    techniqueAndPrice = 5,
    highestEconomicReturn = 6,
    artisticContent = 7,
}

enum TypeCancel {
    default = 'default',
    excluded = 'excluded',
    canceled = 'canceled',
    revoked = 'revoked',
    annulled = 'annulled',
    suspended = 'suspended',
    fail = 'fail',
}

enum ProcessStage {
    internal = 'internal',
    proposals = 'proposals',
    dispute = 'dispute',
    decision = 'decision',
    contract = 'contract',
}

enum AppealStatusType {
    closed = 'closed',
    intent = 'intent',
    request = 'request',
    counterReason = 'counterReason',
}

export type AuctionMessageFile = {
    id: number;
    fileMessage: string;
    originalFileMessage?: string;
    fileName: string;
    originFile: string;
};

type AuctionMessage = {
    id: number;
    createdAt: Date;
    isPublished: number;
    files: AuctionMessageFile[];
    message: string;
    originalMessage?: string;
    deferral?: number;
    user: {
        id: number;
        name: string;
    };
};

interface AuctionMessagesList {
    message: AuctionMessage;
    answer?: AuctionMessage;
    provider?: { companyName: string };
    type: 1 | 2 | 3 | 4;
    isPublished: boolean;
}

interface AuctionTransaction {
    auctionNoticeId: number;
    dateTimeTransaction: Date;
    description: string;
    id: number;
    user: {
        id: number;
        name: string;
    };
    userType: string;
}

interface Participant {
    document: string;
    name: string;
    email: string;
    zipCode?: string;
    state?: string;
    city?: string;
}

interface CreateProcessResponse {
    processId: number;
}

interface AuctionNotice {
    id: number;
    isFavorite: boolean;
    isSuggestion: boolean;
    hasProposal: boolean;
    urlOriginalIcon: string;
    organizationName: string;
    organizationUnitName: string;
    auctionType: AuctionType;
    auctionNumber: string;
    unenforceableNumber?: string;
    accreditationNumber: string;
    budgetAllocationNumber: string;
    itsPriceRegistration: number | null;
    auctionFinished: number;
    auctionCanceled: number;
    typeCancel?: TypeCancel | null;
    startDateTimeDispute: string;
    methodDispute: MethodDispute;
    judgmentCriterion: number | null;
    rangeSupplierParticipation: number;
    rangeRegionParticipation: number;
    auctionFile: string;
    simpleDescription: string;
    auctionStartDate: string;
    auctionEndDate: string;
    auctionApprovedDate: string;
    auctionPublishedDate: string;
    auctionPurchaseYear: string;
    organizationUnitId: number;
    presidentUserId: number;
    competentAuthorityId: number;
    committeeMembers: string;
    biddingStageId: number;
    contractModelId: number;
    supliesProviders: string;
    approveSuppliers: number;
    itsAgreementOrTransfer: number;
    originOfFinancialResource: OriginOfFinancialResourceType | null;
    typeValueBid: TypeValueBidTypes | null;
    agreementNumber: string | null;
    purchaseNumber: number;
    itemGroupingType: ItemGroupingType;
    expirationDatePriceRegistration: number | null;
    isRideAllowed: number | null;
    decimalPlaces: number;
    documents: string;
    globalAmount: number;
    dateTimeInsert: string;
    dateTimeUpdate: string;
    rateTypeBid: number;
    disputeDuration: number;
    legalSupportId?: number;
    pncpLink?: string;
    pncpFileLink?: string;
    ruleId?: string;
    sessionOpened: number;
    isRandomTime: number;
    randomEndTime: number;
    deadline?: string;
    contractInspector?: string;
    deliveryWaysAndAddress?: string;
    integrationFileModel?: IntegrationTypeFile;
    deadlineImpugnment?: string;
    deadlineClarification?: string;
    hideSearches?: number;
    isPhaseReversal?: number;
    appealStatus?: AppealStatusType;
    deadlineAppeal?: string | null;
    deadlineCounterReason?: string | null;
    deadlineIntentAppeal?: string | null;
    isMarketplace?: number;
    integrationsData: {
        integration: string;
        categoryId: number;
        fileTransferType: string;
        description: string;
        field: {
            type: string;
            field: string;
            description: string;
        };
        data: {
            key: string;
            value: string;
        };
    }[];
    participantDocument: string;
    participantName: string;
    participantEmail: string;
    participantId: number;
    lastSendAt: number;
}

interface DetailedAutcionNotice {
    id: number;
    dead: Date;
    agreementOrTransfer: {
        status: boolean;
        origin: string;
    };
    biddingStageId: number;
    isPhaseReversal: number;
    cancelType: TypeCancel | null;
    disputeMethod: {
        description: string;
        id: number;
    };
    decimalPlaces: number;
    files: any[];
    isCanceled: boolean;
    isFinished: boolean;
    isFavorite: boolean;
    simpleDescription: string;
    disputeDuration: number;
    organizationUnit: {
        id: number;
        organizationUnitName: string;
        organization: {
            id: number;
            exemptProviders: number;
            platform: { urlOriginalIcon: string }[];
            organizationName: string;
        };
    };
    judgeCriterion: {
        description: string;
        id: number;
    };
    legalSupport: {
        description: string;
        id: number;
    };
    pncpLink: any;
    priceRegistration: {
        isItRideAllowed: boolean;
        monthsExpiration: number;
        status: boolean;
    };
    processNumber: string;
    processType: AuctionTypeEnum;
    publishedDate: string;
    purcharseNumber: string;
    regionalBenefit: string;
    rule: {
        id: string;
        description: string;
        team: {
            operational: {
                description: string;
            };
            executive: {
                description: string;
            };
            support: {
                description: string;
            };
        };
    };
    segments: { id: number; categoryName: string }[];
    stage: {
        id: number;
        stageName: string;
    };
    startDateTimeToSendProposal: string;
    endDateTimeToSendProposal: string;
    startDateTimeDispute: string;
    deadlineImpugnment: string;
    deadlineClarification: string;
    deadlineAppeal: string;
    deadlineCounterReason: string;
    deadlineIntentAppeal: string;
    rateTypeBid: number;
    team: {
        executive: { id: number; name: string };
        operator: { id: number; name: string };
        supportTeam: { id: number; name: string };
    };
}

interface AuctionNoticeMembers {
    processes: {
        id: number;
        auctionNumber: string;
        accreditationNumber: string;
        presidentUserId: number;
        competentAuthorityId: number;
        committeeMembers: string;
    }[];
    users: {
        id: number;
        name: string;
        email: string;
    }[];
}

// POST /auction-notice/doSearchAuctionNotice
interface GetSearchResumeResponse {
    suggestionCount: number;
    favoriteCount: number;
    proposalCount: number;
}

// POST /auction-notice/verify-organization-can-access-dispute
interface DoVerifyOrganizationCanAcessDisputeBody {
    processId: number;
}
interface DoVerifyOrganizationCanAcessDisputeResponse {
    allLotsJudge: boolean;
    allClassified: boolean;
}

// POST /auction-notice/doApproveSuppliers
interface DoApproveSuppliersBody {
    processId: number;
}
interface DoApproveSuppliersResponse {
    approved: boolean;
}

// GET /auction-notice/${processId}/extraFields
enum ExtraFieldsTypeEnum {
    'text' = 'text',
    'textarea' = 'textarea',
    'number' = 'number',
    'money' = 'money',
    'date' = 'date',
    'select' = 'select',
    'checkbox' = 'checkbox',
    'radio' = 'radio',
}
enum ExtraFieldsEntityEnum {
    'process' = 'process',
    'lotProposals' = 'lotProposals',
}
interface ExtraField {
    id: string;
    organizationId: number;
    ruleId: string;
    description: string;
    type: ExtraFieldsTypeEnum;
    entity: ExtraFieldsEntityEnum;
    options: string;
    createdAt: Date;
    updatedAt: Date;
}
interface GetExtraFieldsReponse extends ExtraField {}

enum StepSearchAuctionNoticeByIdEnum {
    afterPublished = 'afterPublished',
    afterEdited = 'afterEdited',
}

export type {
    GetSearchResumeResponse,
    DoVerifyOrganizationCanAcessDisputeBody,
    DoVerifyOrganizationCanAcessDisputeResponse,
    DoApproveSuppliersBody,
    DoApproveSuppliersResponse,
    AuctionTransaction,
    DetailedAutcionNotice,
    AuctionNoticeOrganizationResume,
    AuctionNoticeOrganization,
    AuctionNoticeMembers,
    AuctionMessagesList,
    AuctionNotice,
    Participant,
    CreateProcessResponse,
    GetExtraFieldsReponse,
};

export {
    OriginOfFinancialResourceType,
    TypeValueBidTypes,
    ItemGroupingType,
    AuctionType,
    MethodDispute,
    IntegrationTypeFile,
    JudgmentCriterion,
    TypeCancel,
    ProcessStage,
    AppealStatusType,
    ExtraFieldsTypeEnum,
    ExtraFieldsEntityEnum,
    StepSearchAuctionNoticeByIdEnum,
};
