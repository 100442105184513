/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Box,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    Popover,
    IconButton,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { AccountBalance, AccountCircle, ArrowDropDown } from '@material-ui/icons';
import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IoBusinessOutline } from 'react-icons/io5';
import { CgEnter } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { MdAdminPanelSettings } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from 'routes/platform.context';
import { apiRedirectTo, getBackofficeRedirectUrl, getUrlOAuth2 } from 'common/utils';
import { CONSTANTS } from 'common/constants';
import { ListEnvironmentsWithPermission } from 'clients/manager/interfaces/environments-user-permission.interface';
import { userRequests } from 'clients/manager/user.requests';
import { requestAccessRequests } from 'clients/manager/request-access.requests';
import { AppState } from 'store';
import { TypeAccess } from 'common/interfaces/user.interface';
import { UseWindowEvent } from 'common/hooks/events.hook';
import { Button } from 'common/components';
import { removeAuthToken } from 'modules/auth/session.utils';
import { useStyles } from './styles';
import { EnvironmentsModalProps } from './props';

const EnvironmentsModal: FC<EnvironmentsModalProps> = () => {
    const { platform } = usePlatformContext();

    const loginBaseUrl = getBackofficeRedirectUrl(true);
    const phpBaseUrl = getBackofficeRedirectUrl();
    const [environments, setEnvironments] = useState<ListEnvironmentsWithPermission>({
        organizations: [],
        providers: [],
    });
    const [requestAccess, setRequestAccess] = useState<ListEnvironmentsWithPermission>({
        organizations: [],
        providers: [],
    });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { authUser, authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth);
    const { t } = useTranslation();

    const resize = useCallback(() => {
        setWindowWidth(window.innerWidth);
    }, [windowWidth]);

    UseWindowEvent('resize', () => resize(), [resize]);

    const classes = useStyles();
    const history = useHistory();

    const getEnvironments = async () => {
        if (!authUser) {
            return;
        }

        const [responsePermissions, responseAuthorizations] = await Promise.all([
            userRequests.listEnvironmentsWithPermission(),
            requestAccessRequests.listEnvironmentsWithAuthorizedAccess(),
        ]);
        setEnvironments(responsePermissions.data ?? { organizations: [], providers: [] });
        setRequestAccess(responseAuthorizations.data ?? { organizations: [], providers: [] });
    };

    useEffect(() => {
        getEnvironments();
    }, [authUser]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const onAccessLike = async (type: TypeAccess, resourceId: string) => {
        let redirectUrl = '';
        switch (type) {
            case TypeAccess.provider:
                redirectUrl = getUrlOAuth2(type, resourceId, '/painel-fornecedor/');
                break;
            case TypeAccess.citizen:
                redirectUrl = getUrlOAuth2(type, resourceId, '/painel-cidadao/');
                break;
            case TypeAccess.management:
            case TypeAccess.organization:
                redirectUrl = getUrlOAuth2(type, resourceId, '/painel/', phpBaseUrl);
                break;
            default:
                break;
        }

        return apiRedirectTo({ backofficeRedirectUrl: loginBaseUrl, redirectUrl, isPublic: true });
    };

    const getDefaultData = () => {
        switch (authUserTokenData?.typeAccess) {
            case TypeAccess.citizen:
                return {
                    title: authUser?.name,
                    icon: AccountCircle,
                    subTitle: t('term.citizen'),
                };

            case TypeAccess.organization: {
                const { organizations } = environments;

                let organization = organizations.find(
                    (org) => Number(org.id) === Number(authUserTokenData?.organizationId)
                );

                if (!organization && requestAccess.organizations.length) {
                    organization = requestAccess.organizations.find(
                        (org) => Number(org.id) === Number(authUserTokenData?.organizationId)
                    );
                }

                return {
                    title: organization?.organizationName,
                    icon: AccountBalance,
                    subTitle: t('term.organization'),
                };
            }

            case TypeAccess.provider: {
                const { providers } = environments;

                let provider = providers.find(
                    (prov) => Number(prov.id) === Number(authUserTokenData?.providerId)
                );

                if (!provider && requestAccess.providers.length) {
                    provider = requestAccess.providers.find(
                        (prov) => Number(prov.id) === Number(authUserTokenData?.providerId)
                    );
                }

                return {
                    title: provider?.companyName,
                    icon: IoBusinessOutline,
                    subTitle: t('term.supplier'),
                };
            }

            case TypeAccess.management:
                return {
                    title: platform?.description ?? 'Licitar digital',
                    icon: MdAdminPanelSettings,
                    subTitle: t('shared.authenticated-header.admin'),
                };

            default:
                return {
                    title: authUser?.name,
                    icon: AccountCircle,
                    subTitle: '',
                };
        }
    };

    const initialData = getDefaultData();
    const IconTitle = initialData.icon;
    const mobile = windowWidth < CONSTANTS.mobileWidth;

    return (
        <Box className={classes.root}>
            {mobile ? (
                <IconButton edge='end' onClick={handleClick} aria-label='modal-change'>
                    <AccountCircle />
                </IconButton>
            ) : (
                <List className={classes.selector}>
                    <ListItem button onClick={handleClick}>
                        <ListItemText
                            classes={{
                                secondary: classes.selectorTextSecondary,
                            }}
                            primary={
                                <Box display='flex'>
                                    <Box mr={1}>
                                        <IconTitle className={classes.selectorIconPrimary} />
                                    </Box>
                                    <Typography className={classes.selectorTextPrimary}>
                                        {initialData?.title}
                                    </Typography>
                                </Box>
                            }
                            secondary={initialData?.subTitle}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge='end' onClick={handleClick} aria-label='modal-change'>
                                <ArrowDropDown />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            )}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box className={classes.content}>
                    <Grid container>
                        <Grid item xs={12} md={4} lg={4}>
                            <Grid item xs={12} className={classes.gridContent}>
                                <Typography
                                    color='primary'
                                    variant='body1'
                                    className={classes.title}
                                >
                                    <IconButton classes={{ root: classes.icon }} color='primary'>
                                        <AccountBalance />
                                    </IconButton>
                                    {t('shared.authenticated-header.area-ente-public')}
                                </Typography>
                                <Box mt={1} mb={2}>
                                    <Typography variant='body2'>
                                        {t('shared.authenticated-header.area-ente-public-info')}
                                    </Typography>
                                    <Box mt={2} mr={1} ml={1}>
                                        {environments.organizations.map((organization) => (
                                            <Box key={organization.id} mb={1} mt={1}>
                                                <Typography
                                                    variant='body2'
                                                    className={classes.optionItem}
                                                    title={organization.organizationName}
                                                    onClick={() =>
                                                        onAccessLike(
                                                            TypeAccess.organization,
                                                            String(organization.id)
                                                        )
                                                    }
                                                >
                                                    {`${organization.organizationName} (${organization.cityName})`}
                                                    <ListItemIcon>
                                                        <CgEnter
                                                            className={classes.optionItemIcon}
                                                        />
                                                    </ListItemIcon>
                                                </Typography>
                                                <Divider />
                                            </Box>
                                        ))}
                                        {!platform?.generalSettings?.general
                                            ?.hideSignupOrganization && (
                                            <Box mt={2}>
                                                <Link
                                                    href='#'
                                                    color='inherit'
                                                    variant='body2'
                                                    onClick={() => {
                                                        setAnchorEl(null);
                                                        history.push('/cadastro-organizacao');
                                                    }}
                                                    underline='none'
                                                    title={t(
                                                        'shared.authenticated-header.cadaster-new-ente-public'
                                                    )}
                                                >
                                                    +{' '}
                                                    {t(
                                                        'shared.authenticated-header.cadaster-new-ente-public-link'
                                                    )}
                                                </Link>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <Grid item xs={12} className={classes.gridContent}>
                                <Typography
                                    color='primary'
                                    variant='body1'
                                    className={classes.title}
                                >
                                    <IconButton classes={{ root: classes.icon }} color='primary'>
                                        <IoBusinessOutline />
                                    </IconButton>
                                    {t('shared.authenticated-header.area-suppliers')}
                                </Typography>
                                <Box mt={1} mb={2}>
                                    <Typography variant='body2'>
                                        {t('shared.authenticated-header.area-suppliers-info')}
                                    </Typography>
                                    <Box mt={2} mr={1} ml={1}>
                                        {environments.providers.map((provider) => (
                                            <Box key={provider.id} mb={1} mt={1}>
                                                <Typography
                                                    key={provider.id}
                                                    variant='body2'
                                                    className={classes.optionItem}
                                                    title={provider.companyName}
                                                    onClick={() =>
                                                        onAccessLike(
                                                            TypeAccess.provider,
                                                            String(provider.id)
                                                        )
                                                    }
                                                >
                                                    {provider.companyName}
                                                    <ListItemIcon>
                                                        <CgEnter
                                                            className={classes.optionItemIcon}
                                                        />
                                                    </ListItemIcon>
                                                </Typography>
                                                <Divider />
                                            </Box>
                                        ))}
                                        {!platform?.generalSettings?.general
                                            ?.hideSignupProvider && (
                                            <Box mt={2}>
                                                <Link
                                                    href='#'
                                                    color='inherit'
                                                    variant='body2'
                                                    onClick={() => {
                                                        setAnchorEl(null);
                                                        history.push('/cadastro-fornecedor');
                                                    }}
                                                    underline='none'
                                                    title={t(
                                                        'shared.authenticated-header.cadaster-suppliers-info'
                                                    )}
                                                >
                                                    +{' '}
                                                    {t(
                                                        'shared.authenticated-header.cadaster-suppliers'
                                                    )}
                                                </Link>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <Grid item xs={12} className={classes.gridContent}>
                                <Typography
                                    color='primary'
                                    variant='body1'
                                    className={classes.title}
                                >
                                    <IconButton classes={{ root: classes.icon }} color='primary'>
                                        <AccountCircle />
                                    </IconButton>
                                    {t('term.hello')} {authUser?.name?.split(' ')[0]}
                                </Typography>
                                <Box mt={1} mb={2}>
                                    <Typography variant='body2'>
                                        {t('shared.authenticated-header.info-msg')}
                                    </Typography>
                                </Box>
                                <Button
                                    variant='contained'
                                    size='xsmall'
                                    onClick={() => onAccessLike(TypeAccess.citizen, String(-1))}
                                >
                                    {t('shared.authenticated-header.acess-simple')}
                                </Button>
                            </Grid>
                            {authUserTokenData?.isSystemAdmin && (
                                <Box mt={3}>
                                    <Grid item xs={12} className={classes.gridContent}>
                                        <Typography
                                            color='primary'
                                            variant='body1'
                                            className={classes.title}
                                        >
                                            <IconButton
                                                classes={{ root: classes.icon }}
                                                color='primary'
                                            >
                                                <MdAdminPanelSettings />
                                            </IconButton>
                                            {t('shared.authenticated-header.admin')}
                                        </Typography>
                                        <Box mt={1} mb={2}>
                                            <Typography variant='body2'>
                                                {t(
                                                    'shared.authenticated-header.info-msg-management'
                                                )}
                                            </Typography>
                                        </Box>
                                        <Button
                                            variant='contained'
                                            size='xsmall'
                                            onClick={() =>
                                                onAccessLike(TypeAccess.management, String(-1))
                                            }
                                        >
                                            {t('shared.authenticated-header.acess-admin')}
                                        </Button>
                                    </Grid>
                                </Box>
                            )}
                            <Grid item xs={12} className={classes.gridContent}>
                                <Box mb={1} mt={2}>
                                    <Link
                                        href='#'
                                        variant='body2'
                                        onClick={() => history.push('/minha-conta')}
                                        underline='none'
                                    >
                                        {t('shared.authenticated-header.my-account')}
                                    </Link>
                                </Box>
                                <Divider />
                                <Box mt={1} mb={2}>
                                    <Link
                                        href='#'
                                        color='inherit'
                                        variant='body2'
                                        onClick={async () => {
                                            removeAuthToken();
                                            history.push('/oauth2/callback/?redirectUri=logout');
                                        }}
                                        underline='none'
                                    >
                                        {t('shared.authenticated-header.exit-app')}
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </Box>
    );
};

export default EnvironmentsModal;
