import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from 'common/components';
import {
    addNotificationApiError,
    addNotificationWarning,
    processStageToBiddingStageId,
} from 'common/utils';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { getDisputeUrl } from 'common/utils/getters/get-url-redirect-dispute.utils';
import { usePlatformContext } from 'routes/platform.context';
import { processUtils } from 'modules/process/process-utils';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { CircularProgress } from '@material-ui/core';
import {
    JudgmentCriterion,
    ProcessStage,
} from '../../../../clients/manager/interfaces/auction-notice.interface';
import { useProcessFormContext } from '../../context/process-form.context';
import { useStyles } from './button-dispute-room-style';

const ButtonDisputeRoom = (props?: ButtonProps) => {
    const { t } = useTranslation();
    const styles = useStyles();

    const { platform } = usePlatformContext();
    const { process, processForm } = useProcessFormContext();
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);

    const [loading, setLoading] = useState(false);

    const handleClick = useCallback(async () => {
        if (!process?.id || loading) {
            return;
        }

        try {
            setLoading(true);
            const reponse = await auctionNoticeRequests.verifyOrganizationCanAcessDispute(
                process.id
            );

            const isOpenDisputeRoom =
                process.judgmentCriterion &&
                ![
                    JudgmentCriterion.bestTechnique,
                    JudgmentCriterion.techniqueAndPrice,
                    JudgmentCriterion.artisticContent,
                ].includes(process.judgmentCriterion);

            if (
                (reponse.data.allClassified && reponse.data.allLotsJudge) ||
                (process.isPhaseReversal && isOpenDisputeRoom)
            ) {
                const { data } = await auctionNoticeRequests.getService(process.id);
                return window.open(
                    getDisputeUrl(process.id, authUserTokenData, data, platform),
                    '_blank'
                );
            }
            const message = !reponse.data.allClassified
                ? 'dispute-button.warning-description'
                : 'dispute-button.warning-jugdment';
            addNotificationWarning({
                title: t('dispute-button.warning-title'),
                message: t(message),
            });
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            setLoading(false);
        }
    }, [process, loading, authUserTokenData, platform, t]);
    const inBiddingStage = useCallback(
        (biddingStage: number[]) =>
            processForm?.values?.biddingStageId &&
            biddingStage.includes(processForm.values.biddingStageId),
        [processForm?.values?.biddingStageId]
    );

    if (
        !process ||
        !inBiddingStage(
            processStageToBiddingStageId([
                ProcessStage.dispute,
                ProcessStage.contract,
                ProcessStage.decision,
            ])
        )
    ) {
        return null;
    }

    const textButton = processUtils.isDispenseWithoutDispute(processForm?.values)
        ? t('term.judgment')
        : t('term.dispute');

    return (
        <Button
            {...props}
            color='secondary'
            variant='contained'
            size='xsmall'
            title={t('term.dispute')}
            onClick={handleClick}
            disabled={loading}
        >
            {loading ? `${t('term.loading')}...` : textButton}
            {loading && <CircularProgress className={styles.loading} />}
        </Button>
    );
};

export default memo(ButtonDisputeRoom);
